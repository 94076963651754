import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/utils/helpers"
import { Icon } from "@/features/icons/components/IconLoader"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

const buttonVariants = cva(
  "inline-flex items-center justify-center outline-transparent whitespace-nowrap gap-2 outline-1 enabled:cursor-pointer rounded-full text-sm font-medium transition-colors h-10 outline-offset-1 ",
  {
    variants: {
      variant: {
        default:
          "text-on-primary bg-primary enabled:hover:shadow-sm disabled:bg-on-surface/20 disabled:text-on-surface/50 focus:outline-primary",
        tonal:
          "bg-secondary-container text-on-secondary-container enabled:hover:shadow-sm disabled:bg-on-surface/20 disabled:text-on-surface/50 focus:outline-primary",
        outline:
          "border border-secondary text-primary enabled:hover:bg-primary/10 enabled:focus:bg-primary/10 enabled:focus:border-primary enabled:active:bg-primary/10 enabled:active:border-primary disabled:border-on-surface/20 disabled:text-on-surface/50 ",
        text: "border border-transparent text-primary enabled:hover:bg-primary/10 enabled:focus:bg-primary/10 enabled:focus:border-primary/20 enabled:active:bg-primary/10 enabled:active:border-primary disabled:text-on-surface/50",
      },
      size: {
        default: "h-10  px-4 py-2  min-w-10",
        icon: "h-9 w-9 text-on-surface-variant enabled:hover:bg-on-surface-variant/10 enabled:focus:bg-on-surface-variant/15 enabled:active:bg-on-surface-variant/20 flex justify-center items-center border-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  icon?: IconProp
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, children, icon, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size }), className, "outline")}
        ref={ref}
        aria-label={props["aria-label"] || "Button"}
        role="button"
        tabIndex={0}
        {...props}
      >
        {icon && <Icon icon={icon as string} />}
        {children}
      </Comp>
    )
  },
)
Button.displayName = "Button"

export { Button, buttonVariants }
