/** @type {import('next-i18next').UserConfig} */
const path = require("path")
const HttpBackend = require("i18next-http-backend/cjs")
const { uniqueId } = require("lodash")
const ChainedBackend = require("i18next-chained-backend").default
const LocalStorageBackend = require("i18next-localstorage-backend").default

const isBrowser = typeof window !== "undefined"

// set the expiration time for the cache to 7 days as defaultVersion is updated every build
const expirationTime = 7 * 24 * 60 * 60 * 1000

// generate a new version every build to refresh the cache
const defaultVersion = uniqueId("v")

module.exports = {
  // debug: isDev,
  backend: {
    backendOptions: [{ expirationTime, defaultVersion }],
    backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
  },
  // partialBundledLanguages: isBrowser && true,
  i18n: {
    defaultLocale: "en",
    locales: ["en", "da", "no"],
    localePath: path.resolve("./public/locales"),
  },
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
}
