import { getSpaceFromToken, signOut } from "@/api/authentication"
import { meAtom } from "@/atoms/users"
import { Login } from "@/features/auth/components/Login"
import { SignUp } from "@/features/auth/components/SignUp"
import { faApartment } from "@awesome.me/kit-44b29310a6/icons/duotone/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { Tooltip } from "antd"
import dayjs from "dayjs"
import "dayjs/locale/da"
import "dayjs/locale/en"
import "dayjs/locale/nb"
import "dayjs/locale/sv"
import { isEmpty, map, uniqBy } from "lodash"
import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil"
import { toast } from "sonner"
import { changeSpace, selectedSpaceAtom, spacesAtom } from "../../atoms/spaces"
import RedirectProvider from "../../features/redirection/RedirectProvider"
import { useIsMounted } from "../../hooks/useIsMounted"
import {
  ImageWithFallback,
  ORGANISATION_AVATERS,
} from "../../utils/ImageWithFallback"
import { fetcher } from "../../utils/api"
import { setItem } from "../../utils/browserStorage"
import { dayjsLocaleMapper } from "../../utils/helpers"
import { Loader } from "../spinners/loader"
import { SignedIn } from "./SignedIn"
import { SignedOut } from "./SignedOut"

const FLEXTRIBE_EMPLOYEE_EMAILS = [
  "meg@flextribe.io",
  "pl@flextribe.io",
  "mp@flextribe.io",
  "nld@flextribe.io",
]

const OrganisationProvider = ({ children }) => {
  const [user, setUser] = useRecoilState(meAtom)

  const [selectedSpace, setSelectedSpace] = useRecoilState(selectedSpaceAtom)

  const [selectedOrganisation, setSelectedOrganisation] = useState<{
    uid?: string
    name?: string
  }>({})

  const [_changeSpace, setChangeSpace] = useRecoilState(changeSpace)
  const [loading, setLoading] = useState(true)
  const { contents: spaces, state } = useRecoilValueLoadable(spacesAtom)
  const router = useRouter()
  const { returnUrl, ...queryParams } = router.query

  const getOrganisations = () => {
    return uniqBy(map(spaces, "organisation"), "uid")
  }
  const getOrganisationSpaces = (uid) => {
    const orgSpaces = spaces.filter((item) => item.organisation?.uid === uid)
    return orgSpaces.filter(
      (item) =>
        item.is_accessible === true ||
        FLEXTRIBE_EMPLOYEE_EMAILS.includes(user.email),
    )
  }

  const setSpace = async (item, redirect = false) => {
    if (item) {
      setSelectedSpace(item)
      setItem("_ft_a_", item.token.token)
      const pathname = returnUrl ?? "/"
      if (redirect) {
        const queryString = new URLSearchParams(
          queryParams as Record<string, string>,
        ).toString()
        window.location.assign(
          `${pathname}${queryString && queryString !== "" ? `?${queryString}` : ""}`,
        )
        return
      }

      dayjs.locale(dayjsLocaleMapper[item?.locale] ?? "da")
      setLoading(false)
      setChangeSpace(false)

      await fetcher("/me").then((data) => setUser(data))
    }
  }

  useEffect(() => {
    if (_changeSpace === true && selectedSpace?.organisation) {
      localStorage.clear()
      setSelectedOrganisation(selectedSpace?.organisation)
    }
  }, [_changeSpace])

  useEffect(() => {
    if (state === "hasValue") {
      const processSpaces = async () => {
        let spaceFromToken = {
          ...(spaces?.find(({ uid }) => uid === getSpaceFromToken()) ?? {}),
        }

        if (spaces === undefined) {
          signOut()
          return
        }

        const accessibleSpaces = spaces.filter((x) => x?.is_accessible)
        const isOnlySpace = accessibleSpaces?.length === 1
        if (accessibleSpaces && isOnlySpace) {
          spaceFromToken = { ...(accessibleSpaces?.[0] ?? {}) }
        }

        if (
          FLEXTRIBE_EMPLOYEE_EMAILS.includes(user.email) &&
          !isEmpty(spaceFromToken)
        ) {
          spaceFromToken.is_accessible = true
        }

        if (spaceFromToken?.is_accessible === true) {
          const shouldRedirect =
            returnUrl !== undefined &&
            returnUrl !== "" &&
            returnUrl !== "/?returnUrl=" &&
            !selectedSpace &&
            isOnlySpace
          await setSpace(spaceFromToken, shouldRedirect)
          return
        }

        if (spaces !== undefined) {
          setSelectedOrganisation(spaces[0].organisation)
        }
        setChangeSpace(true)
        setLoading(false)
      }

      processSpaces()
    }
  }, [state])

  useEffect(() => {
    if (user?.uid) {
      if (window._cio) {
        window._cio.identify({
          id: user.space_user_uid,
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          space: user.space,
          created_at: Math.round(new Date(user.created).getTime() / 1000),
          updated_at: Math.round(new Date(user.updated).getTime() / 1000),
        })

        window._cio.page(router.asPath)
      }
    }
  }, [user])

  if (loading) return <Loader />

  const selectSpace = (item) => {
    setSpace(item, true)
  }

  if (_changeSpace) {
    return (
      <div className="relative h-screen w-screen bg-blue-100">
        <div className="absolute top-5 flex w-screen flex-col justify-center pl-1 xl:left-[50%] xl:w-[600px] xl:translate-x-[-50%]">
          <div className="mb-2 flex items-center text-xs font-bold">
            Organisation
          </div>
          <div className="no-scrollbar mb-5 flex items-center gap-2 overflow-x-auto">
            {getOrganisations()?.map((item, i) => (
              <div
                onClick={() => setSelectedOrganisation(item)}
                className="h-20 min-w-[8rem] cursor-pointer gap-2 rounded-md border-2 border-gray-200 bg-gray-200 bg-opacity-60 p-1 hover:border-gray-300 hover:bg-gray-300 aria-selected:border-2 aria-selected:border-blue-500"
                aria-selected={selectedOrganisation?.uid === item?.uid}
                key={item?.uid + i}
              >
                <Tooltip title={item?.name ?? ""}>
                  <div className="relative flex h-full w-full items-center justify-center">
                    <ImageWithFallback
                      fill
                      alt={item?.name ?? ""}
                      fallback={
                        <FontAwesomeIcon size="2x" icon={faApartment} />
                      }
                      src={ORGANISATION_AVATERS(item?.uid)}
                    />
                  </div>
                </Tooltip>
              </div>
            ))}
          </div>
          <div className="select-none">
            <div className="mb-2 flex items-center text-xs font-bold">
              Spaces
            </div>
            <div className="flex flex-wrap gap-2">
              {getOrganisationSpaces(selectedOrganisation?.uid)?.map(
                (item, i) => (
                  <div
                    onClick={() => selectSpace(item)}
                    className="flex h-10 w-[98%] cursor-pointer items-center gap-4 rounded-md bg-gray-200 bg-opacity-60 px-3 py-8 hover:bg-gray-300 md:w-[49%]"
                    key={item.uid + "space" + i}
                  >
                    <div className="max-h-8 w-[110px] items-center">
                      <ImageWithFallback
                        width={0}
                        height={0}
                        alt={item.name ?? ""}
                        fallback={<FontAwesomeIcon icon={faApartment} />}
                        src={ORGANISATION_AVATERS(item.uid)}
                      />
                    </div>
                    <div className="flex-1 text-sm font-medium">
                      {!!item.name ? item.name : item.reference}
                    </div>
                  </div>
                ),
              )}
            </div>
            <div className="my-4 w-full border"></div>
          </div>
        </div>
      </div>
    )
  }

  return <>{children}</>
}

const useAuthValidator = () => {
  const isMounted = useIsMounted()
  const setUser = useSetRecoilState(meAtom)
  const router = useRouter()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const space = useRecoilValue(selectedSpaceAtom)

  const fetchMe = useCallback(async () => {
    try {
      const data = await fetcher("/me")

      if (data) {
        setIsLoggedIn(true)
        setUser(data)
      }
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isMounted()) {
      if (router.query["_ft_c"]) {
        try {
          setItem("_ft_a_", router.query["_ft_c"].toString())

          const { _ft_c, ...rest } = router.query

          router.replace({
            pathname: router.pathname,
            query: rest,
          })
          fetchMe()
        } catch (error) {
          toast.error(error.message)
        }
      }
    }
  }, [router])

  useEffect(() => {
    if (isMounted()) {
      fetchMe()
    }

    const setLastOnline = () => {
      const dateTime = new Date().toISOString()
      localStorage.setItem("lastOnline", dateTime)
    }

    window.addEventListener("beforeunload", setLastOnline)

    return () => {
      window.removeEventListener("beforeunload", setLastOnline)
    }
  }, [isMounted, fetchMe])

  return { isLoading, isLoggedIn, space }
}

function AuthenticationProvider({ children }) {
  const [mounted, setMounted] = useState<Boolean | null>(null)
  const [signedUp, setSignedUp] = useState<Boolean>()
  const { isLoading, space } = useAuthValidator()
  const isMounted = useIsMounted()

  //  useEffect(() => {
  //    fetcher("/me/signed_up").then(({ signed_up }) => {
  //      setSignedUp(signed_up)
  //    })
  //  }, [])
  useEffect(() => {
    const fetchSignedUpStatus = async () => {
      try {
        const { signed_up } = await fetcher("/me/signed_up")
        setSignedUp(signed_up)
      } catch (error) {
        console.error("Failed to fetch signed-up status: ", error)
      }
    }

    fetchSignedUpStatus()
  }, [])

  useEffect(() => {
    if (isMounted()) {
      setMounted(true)
    }
  }, [isMounted])

  if (mounted === null) return null
  if (isLoading) return <Loader />

  return (
    <RedirectProvider>
      <LocalizationProvider
        adapterLocale={dayjsLocaleMapper[space?.locale] || "en"}
        dateAdapter={AdapterDayjs}
      >
        <SignedIn>
          <OrganisationProvider>
            {signedUp === false ? <SignUp /> : children}
          </OrganisationProvider>
        </SignedIn>
        <SignedOut>
          <Login />
        </SignedOut>
      </LocalizationProvider>
    </RedirectProvider>
  )
}

export default AuthenticationProvider
