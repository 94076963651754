export const formatTypeValues = (value, type) => {
  switch (type) {
    case "money":
    case "price_range":
      return {
        value: value?.value ?? value,
        currency: value?.currency,
        locale: value?.locale,
      }
    default:
      return value
  }
}

export const roundToThreeDecimals = (value: number) => {
  return Math.round(value * 1000) / 1000
}

export const hexToRgb = (hex: string): string => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
    : "0, 0, 0"
}

export const isValidDomain = (domain: string) => {
  const regex = new RegExp(
    /^([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?)$/,
  )
  return regex.test(domain)
}
