import { getThemeImageUrl } from "@/utils/helpers"
import { useTheme } from "context/theme-context"
import { isEmpty } from "lodash"
import { useRouter } from "next/router"
import { useCallback, useEffect, useRef, useState } from "react"
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  REGEXP_ONLY_DIGITS_AND_CHARS,
} from "../../shared/components/ui/otp"
import { REDIRECTION_HOSTS } from "./RedirectProvider"
import { ILink, fetchLink } from "./utils/fetchLink"

export const Redirection = () => {
  const router = useRouter()

  const [errorMessage, setErrorMessage] = useState(null)
  const [isPasswordProtected, setIsPasswordProtected] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const linkHost = useRef<string | null>(null)
  const destinationUrl = useRef<string | null>(null)

  const theme = useTheme()
  const authLogo = getThemeImageUrl(theme?.authlogo ?? "")

  const redirectToHost = useCallback(() => {
    if (linkHost.current && window) {
      window.location.replace(`https://${linkHost.current}`)
    }
  }, [linkHost])

  const redirectToDestination = useCallback(() => {
    if (destinationUrl.current && window) {
      window.location.replace(destinationUrl.current)
    }
  }, [destinationUrl])

  const errorHandler = ({
    error_code,
    host,
    link_type,
    deleted_date,
  }: ILink) => {
    let errorMessage = null
    switch (error_code) {
      case "NOT_AUTHORIZED":
        errorMessage = "You are not authorized to access this link"
        break
      case "LIMIT_REACHED":
        errorMessage = "Limit reached for this link"
        break
      case "PASSWORD_REQUIRED":
        if (isPasswordProtected) {
          triggerError("Incorrect password. Please try again.", false)
        }
        setIsPasswordProtected(true)
        setIsRedirecting(false)
        break
      case "NOT_FOUND":
        errorMessage = "Link not found"
        break
      case "DELETED":
        if (link_type === "rfq") {
          errorMessage = `The request for quote that you are looking for has been deleted on ${new Date(deleted_date)?.toLocaleDateString()}. \nThe link is therefore no longer available. \nYou are being redirected to the frontpage.`
          break
        }
        if (link_type === "tasks") {
          errorMessage = `The task that you are looking for has been deleted on ${new Date(deleted_date)?.toLocaleDateString()}. \nThe link is therefore no longer available. \nYou are being redirected to the frontpage.`
          break
        }
        errorMessage = `The destination you are looking for has been deleted.
         You are being redirected to the frontpage.`
        break
      default:
        errorMessage = "Something went wrong. Please try again later."
        break
    }

    if (errorMessage) {
      triggerError(errorMessage)
      return
    }
  }

  const triggerError = (error: string, redirect: boolean = true) => {
    setErrorMessage(error)
    setIsRedirecting(false)

    if (redirect) {
      setTimeout(() => {
        redirectToHost()
      }, 10000)
    }
  }

  const fetchTracking = async (password?: string) => {
    setErrorMessage(null)
    setIsRedirecting(true)

    try {
      const link = await fetchLink(router.query.code as string, password)

      if (link.host) {
        linkHost.current = link.host
        await theme.refereshWithHost(linkHost.current)
      }

      if (window.location.port === "8080") {
        linkHost.current = "localhost:8080"
      }

      if (link.error_code) {
        errorHandler(link)
        return
      }

      if (window) {
        const queryString = {}
        try {
          let _url = link.destination
          if (!link.destination || !link.destination.startsWith("http")) {
            _url = `${window.location.protocol}//${linkHost.current}${link.destination}`
          }
          const { host, search, pathname, protocol } = new URL(_url)

          const searchParams = new URLSearchParams(search)
          for (const [key, value] of searchParams) {
            queryString[key] = value
          }

          if (link.authentication?.token) {
            queryString["_ft_c"] = link.authentication.token
          }

          const formattedQueryString = new URLSearchParams(
            queryString,
          ).toString()
          destinationUrl.current = `${protocol}//${host}${pathname}${formattedQueryString.length > 0 ? `?${formattedQueryString}` : ""}`

          if (!REDIRECTION_HOSTS.includes(window.location.hostname)) {
            router.push(link.destination)
          } else {
            redirectToDestination()
          }
        } catch (error) {
          console.log(error)
          throw new Error(error)
        }
      }
    } catch (error) {
      triggerError(String(error))
    }
  }

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!isEmpty(router.query.code)) {
      fetchTracking()
    }
  }, [router.query.code])

  const manuallyRedirect = () => {
    const input = inputRef.current
    if (!input) {
      triggerError("Something went wrong. Please try again later.")
      return
    }

    if (isPasswordProtected) {
      const password = (input as HTMLInputElement).value
      if (password) {
        fetchTracking(password)
      }
      return
    }
  }

  let content = (
    <>
      <div className="animate-pulse text-2xl font-bold">Hang Tight...</div>
      {!isRedirecting ? (
        <div className="text-md animate-pulse font-bold">
          We&apos;re fetching the link for you
        </div>
      ) : (
        <>
          <div className="text-md font-bold">
            We&apos;re redirecting you to the desired link
          </div>
          {errorMessage === null && (
            <div
              className="cursor-pointer text-xs"
              onClick={() => redirectToDestination()}
            >
              Click here if not automatically redirected
            </div>
          )}
        </>
      )}
    </>
  )

  if (errorMessage && !isPasswordProtected) {
    content = (
      <div className="flex flex-col items-center gap-2">
        <div className="text-md whitespace-pre-wrap font-bold text-red-500">
          {errorMessage}
        </div>
        <div className="animate-pulse text-2xl font-bold">Hang Tight...</div>
        <div className="text-md font-bold text-gray-500">
          We will redirect you to the platform.
        </div>
        <div
          className="cursor-pointer text-xs"
          onClick={() => redirectToHost()}
        >
          Click here if not automatically redirected
        </div>
      </div>
    )
  }

  if (isPasswordProtected && !isRedirecting) {
    const inputStyle = "border-gray-300 text-sm font-bold"
    content = (
      <div className="flex flex-col items-center gap-2">
        <div className="text-md font-bold text-gray-500">
          Enter access code for this link
        </div>
        <InputOTP
          onChange={(e) => {
            if (e.length === 6) {
              manuallyRedirect()
            }
          }}
          ref={inputRef}
          pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
          maxLength={6}
          autoFocus={true}
        >
          <InputOTPGroup>
            <InputOTPSlot className={inputStyle} index={0} />
            <InputOTPSlot className={inputStyle} index={1} />
            <InputOTPSlot className={inputStyle} index={2} />
            <InputOTPSlot className={inputStyle} index={3} />
            <InputOTPSlot className={inputStyle} index={4} />
            <InputOTPSlot className={inputStyle} index={5} />
          </InputOTPGroup>
        </InputOTP>
        <div className="mb-2 text-red-500">{errorMessage}</div>
      </div>
    )
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-2">
      {content}
      <div
        style={{ backgroundImage: authLogo }}
        className="mx-auto w-40 bg-contain bg-center bg-no-repeat pb-8"
      />
    </div>
  )
}
